import React from "react"
import SEO from "../components/seo"
import { Footer, FooterLinks } from "../components/Layout/Footer"
import Layout from "../components/Layout"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

const NotFoundPage = () => (
  <Layout>
      <SEO title={'404'} />
      <Container style={{ paddingBottom: '20vh', paddingTop: 16 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box position="relative" style={{ paddingTop: "56.5%", backgroundColor: "#000" }}>
              
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h4" gutterBottom>
              Not Found
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <FooterLinks />
    </Layout>
)

export default NotFoundPage
